import React, { useState, useEffect, MouseEvent, ChangeEvent } from 'react'
import { differenceInYears } from 'date-fns'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import useWidth from 'src/hooks/window/useWidth'
import RangeInput from '../../../RangeInput/Form'
import AcceptTerms from 'src/components/AcceptTerms/ContactForm'
import { sendDataLayerEvent, getParameterByName, sendCDPFormData } from 'src/shared/helpers'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import * as URLS from 'src/config/api/Urls'

import IntervalInterOneJSON from '../../../RangeInput/assets/intervalInterOne.json'
import PercentageInterOneJSON from '../../../RangeInput/assets/percentageInterOne.json'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import errorImg from 'src/assets/images/shared/erro-pig.png'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import BaixeOApp from 'src/assets/images/qrcode-header.jpg'
import Success from '../images/sucesso.png'
import QrCodeMais50 from './assets/images/qr-code-inter-mais-de-50-mil.png'
import QrCodePortability from './assets/images/qr-code-inter-portability.png'

import { Container, CloseButton, CardAlert } from './style'
import { apiForm } from 'src/config/api/axiosRequest'

type InterOneContactFormProps = {
  closeModal: () => void;
  dobra?: string;
}
interface IFormValues {
  nome: string;
  sobrenome: string;
  email: string;
  celular: string;
  cpf: string;
  datanascimento: string;
  cliente?: string;
  serinterone: string;

}
const valueLead = {
  0: 0,
  10000: 50000,
  20000: 60000,
  30000: 70000,
  40000: 80000,
  50000: 90000,
  60000: 100000,
  70000: 120000,
  80000: 130000,
  90000: 140000,
  100000: 150000,
  110000: 160000,
  120000: 170000,
  130000: 180000,
  140000: 190000,
  150000: 200000,
  160000: 210000,
  170000: 220000,
  180000: 230000,
  190000: 240000,
  200000: 250000,

}

function InterOneContactForm ({ closeModal, dobra }: InterOneContactFormProps) {
  const WIDTH_LG = 1024
  const WIDTH_MD = 768
  const windowWidth = useWidth(200)
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ underAge, setUnderAge ] = useState(false)
  const [ isClient, setIsClient ] = useState(true)
  const [ isClientFinancing, setIsClientFinancing ] = useState(false)
  const [ isClientPortability, setIsClientPortability ] = useState(false)
  const [ rangeValue, setRangeValue ] = useState<number>(0)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)
  const [ select, setSelect ] = useState('')
  const bottomText = 'Abra a câmera do seu celular e aponte para o QR Code acima.'

  const [ sendDatalayerEvents ] = useDataLayer()

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
  }, [])

  const sendForm = async (data: IFormValues) => {
    setLoading(true)
    const newDate = data.datanascimento.replace(/\D/g, ',').split(',').reverse().join(',')
    const result = differenceInYears(new Date(), new Date(newDate))

    const isClientCheck = data.cliente
    delete data.cliente

    const formData = {
      ...data,
      cpf: data.cpf.replace(/\D/g, ''),
      celular: data.celular.replace(/\D/g, ''),
      datanascimento: data.datanascimento.replace(/\D/g, ',').split(',').reverse().join('-'),
      termoaceito: accept,
      investir: valueLead[rangeValue],
      utmCampaign: utmCampaign?.toString(),
      utmMedium: utmMedium?.toString(),
      utmSource: utmSource?.toString(),
      utmContent: utmContent?.toString(),
    }

    if (formData.range) {
      delete formData.range
    }

    try {
      if (result < 18) {
        setUnderAge(true)
      } else {
        await apiForm.post(`${URLS.CONTACT_FORM_POST_V5}/lead-lp-one`, [ formData ])
        setSent(true)
        sendDataLayerEvent('form_submitted')
        sendDatalayerEvents({
          section: 'Inter One Você na frente',
          section_name: 'Vimos que você tem menos de 18 anos.',
          element_action: 'submit',
          element_name: `Enviar - ${data.serinterone}`,
          redirect_url: 'null',
          step: `Já tem conta no inter - ${isClientCheck}`,
          element_search: data.serinterone === 'Investimento' && valueLead[rangeValue],
          element_previous: `Quero ser Inter One ${dobra}`,
         })
        isClientCheck === 'nao' && setIsClient(false)
        data.serinterone === ('FinanciamentoImobiliario') ? setIsClientFinancing(true) : setIsClientFinancing(false)
        data.serinterone === ('PortabilidadeSalario') ? setIsClientPortability(true) : setIsClientPortability(false)
      }
      sendCDPFormData({ formName: 'Formulario Inter One', cpf: formData.cpf, email: formData.email })
    } catch (e) {
      setError(true)
      setLoading(false)
    }
  }

  const changeRange = (rangeValueInput: string) => {
    setRangeValue(parseInt(rangeValueInput))
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
  }

  const asUnderAge = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    if (windowWidth < WIDTH_LG) {
      window.location.href = 'https://intergo.app/7820e737'
    } else {
      window.location.href = '/pra-voce/conta-digital/conta-kids/'
    }
  }

  const closeButton = closeModal && (
    <CloseButton className='border-0 bg-transparent pt-3' onClick={closeModal}>
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  return (
    <>
      { error && (
        <Container isModal={closeModal} className='d-flex align-items-center error'>
          {closeButton}
          <div className='col-12 text-center'>
            <h3 className='fs-20 lh-24 mb-5'>Ah não! Ocorreu um erro com a sua solicitação...</h3>
            <img src={errorImg} alt='Imagem de erro' />
            <p className='text-grayscale--400 fs-18 lh-22 pt-3'>
              Verifique a sua conexão com a internet e tente novamente. <br />
              <button
                onClick={handleReturn}
                title='Clique aqui para voltar'
                className='bg-transparent border-0 fw-600'
              >
                Clique aqui
              </button>
              para voltar.
            </p>
          </div>
        </Container>
      )}
      { sent && (
        <Container isModal={closeModal} className='d-flex align-items-center sent'>
          {closeButton}
          <div className='col-12 text-left px-0'>
            <p className='fs-16 lh-20 text-grayscale--400 fw-600'>
              { isClientFinancing && <span>Recebemos o seu interesse em migrar<br /> para o Inter One através do<br /> financiamento imobiliário.</span> }
              { (isClientPortability && windowWidth < WIDTH_MD) && <span>Que bom que você se interessou em<br /> migrar para o Inter One por meio da<br /> Portabilidade de Salário.</span> }
              { (!isClientFinancing && !isClientPortability && windowWidth < WIDTH_MD) && <span>Que bom que você se interessou em<br /> migrar para o Inter One por meio dos<br /> seus investimentos.</span> }
              { (windowWidth >= WIDTH_MD && !isClientFinancing) && <span>Recebemos os seus dados</span>}
            </p>
            {
              isClient
              ? (
                <h3 className='fs-24 lh-30 text-grayscale--500 mb-4 fw-600'>
                  {!isClientFinancing && !isClientPortability && windowWidth >= WIDTH_MD &&
                    <span className='d-md-block'>
                      Você já pode <span className='text-orange--extra'>acessar o app,</span>
                    </span>}
                  {
                     isClient && isClient !== isClientFinancing && isClient !== isClientPortability
                     ? windowWidth < WIDTH_MD ? ' Para finalizar, clique no botão abaixo e aproveite todos os benefícios que só a Inter Invest pode oferecer. ' : ' e fazer seus investimentos pra se tornar Inter One.'
                    : isClientFinancing ? <span> Para finalizar, clique no<br /> botão abaixo e simule o<br /> valor das suas parcelas<br />com a gente. </span>
                     : isClientPortability && windowWidth < WIDTH_MD ? <span>Para finalizar, clique no botão abaixo e aproveite todos os benefícios que só o nosso Super App pode oferecer. </span> : <span> Para finalizar, <span className='text-orange--extra'>acesse o app</span> e continue com a sua solicitação de portabilidade.</span>
                  }
                </h3>
              )
              : (
                <h3 className='fs-24 lh-30 text-grayscale--500 mb-4 fw-600'>
                  <span className='d-block'>Agora <span className='text-orange--extra'>baixe nosso app,</span></span>
                  pra se tornar Inter One e aproveitar os benefícios.
                </h3>
              )
            }
            <div className='col-12 pt-2 mx-auto d-md-none text-left'>
              {isClientFinancing
                ? <a className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none' target='_blank' rel='noreferrer' href='https://imobiliario.inter.co/financiamento-imobiliario/#/informacoes-imovel?utm_source=site&utm_campaign=cr_lpone_formulario&utm_medium=crm&utm_content=seja_one'> Simular financiamento imobiliário </a>
                : isClientPortability ? <a className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none' target='_blank' rel='noreferrer' href='bancointer://portSalario?utm_source=site&utm_medium=crm&utm_campaign=cr_lpone_formulario'> Solicitar portabilidade agora </a>
                : !isClientFinancing && !isClientPortability && <a className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none' target='_blank' rel='noreferrer' href='bancointer://investimentos?utm_source=site&utm_medium=crm&utm_campaign=cr_lpone_formulario'> Investir agora </a>
              }
            </div>
            <div className={`${!isClientFinancing ? 'col-12 pt-2 px-0 text-md-center text-left mx-auto d-none d-md-block' : 'col-12 pt-2 px-0 text-left mx-auto d-none d-md-block' }`}>
              {
                !isClient ? <img src={BaixeOApp} alt='Abra sua conta no Inte 1' />
                : isClient !== isClientFinancing && isClient !== isClientPortability
                  ? windowWidth < WIDTH_MD ? <a className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none' target='_blank' rel='noreferrer' href='bancointer://investimentos?utm_source=site&utm_medium=crm&utm_campaign=cr_lpone_formulario'> Investir agora </a> : <img src={QrCodeMais50} alt='Invista para se tornar Inter One' />
                  : isClientFinancing
                  ? <a className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none' target='_blank' rel='noreferrer' href='https://imobiliario.inter.co/financiamento-imobiliario/#/informacoes-imovel?utm_source=site&utm_campaign=cr_lpone_formulario&utm_medium=crm&utm_content=seja_one'> Simular financiamento imobiliário </a>
                  : isClientPortability && windowWidth < WIDTH_MD ? <a className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none' target='_blank' rel='noreferrer' href='bancointer://portSalario?utm_source=site&utm_medium=crm&utm_campaign=cr_lpone_formulario'> Solicitar portabilidade agora </a> : <img src={QrCodePortability} alt='Faça sua portabilidade' />
              }
            </div>
            {!isClientFinancing &&
              <div className='col-12 d-none d-md-block px-0'>
                <hr />
                { isClient
                    ? (
                      <p className='fs-14 lh-17'>
                        {bottomText}
                        {isClient && isClient !== isClientPortability
                        ? ' Você irá direto pra área de investimentos do app. É rápido e fácil!'
                        : isClientPortability && ' Você irá direto pra área de portabilidade de salário do app. É rápido e fácil!'
                        }
                      </p>
                    )
                    : (
                      <p className='fs-14 lh-17'>
                        Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter e clicar
                        em “Abrir conta completa e gratuita”. É rápido e fácil!
                      </p>
                  )
                  }
              </div>
            }
          </div>
        </Container>
      )}
      { underAge && (
        <Container isModal={closeModal} className='d-flex align-items-center under-age'>
          {closeButton}
          <div className='col-12 px-md-0 text-center'>
            <h3 className='fs-16 lh-20 text-grayscale--400 fw-400 fw-md-600'>
              Vimos que você tem menos de 18 anos.
            </h3>
            <p className='fs-20 lh-23 fs-md-24 lh-md-28 text-grayscale--500 mb-4 fw-600'>
              <span className='d-md-block'>
                Conheça nossa
                <span
                  className='text-orange--extra cursor-pointer'
                  onClick={asUnderAge}
                > Conta Kids
                </span>,
              </span> que também é completa, digital e gratuita.
            </p>
            <div className='col-12 pb-2 mx-auto d-md-none text-center'>
              <img src={Success} alt='Sucesso' className='success' />
            </div>
            <div className='col-12 pb-2 mx-auto d-none d-md-block text-center'>
              <img src={BaixeOApp} alt='Baixe o App Inter' />
            </div>
          </div>
        </Container>
      )}
      <Container
        isModal={closeModal}
        className={`${error || sent || underAge ? 'd-none' : 'd-block'} `}
      >
        {closeButton}
        <div className='pt-3 pb-4 mb-2'>
          <h3 className='fs-20 lh-25 fs-md-24 lh-md-30 text-grayscale--500 mb-0'>
            Preencha seus dados para receber nosso contato
          </h3>
        </div>
        <form name='open_account' onSubmit={handleSubmit(sendForm)}>
          <div className='form--default'>
            <div className='row'>
              <div className={`col-12 col-md-6 pb-3 d-flex flex-column ${errors.nome && 'error'}`}>
                <label htmlFor='nome' className='fs-14 lh-18 fw-600'>Nome</label>
                <input
                  ref={register({
                    required: 'Nome obrigatório',
                  })}
                  name='nome'
                  id='nome'
                  type='text'
                  maxLength={100}
                  placeholder='Digite seu nome'
                />
                {errors.nome && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.nome.message}</p>}
              </div>
              <div className={`col-12 col-md-6 pb-3 d-flex flex-column ${errors.sobrenome && 'error'}`}>
                <label htmlFor='sobrenome' className='fs-14 lh-18 fw-600'>Sobrenome</label>
                <input
                  ref={register({
                    required: 'Sobrenome obrigatório',
                  })}
                  name='sobrenome'
                  id='sobrenome'
                  type='text'
                  maxLength={100}
                  placeholder='Digite seu sobrenome'
                />
                {errors.sobrenome && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.sobrenome.message}</p>}
              </div>
              <div className={`col-12 pb-3 d-flex flex-column ${errors.email && 'error'}`}>
                <label htmlFor='email' className='fs-14 lh-18 fw-600'>E-mail</label>
                <input
                  ref={register({
                    required: 'E-mail obrigatório',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Insira um e-mail válido',
                    },
                  })}
                  name='email'
                  id='email'
                  type='text'
                  placeholder='Digite seu e-mail'
                />
                {errors.email && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.email.message}</p>}
              </div>
              <div className={`col-12 col-md-6 pb-3 d-flex flex-column ${errors.celular && 'error'}`}>
                <label htmlFor='celular' className='fs-14 lh-18 fw-600'>Celular</label>
                <input
                  ref={register({
                    required: 'Telefone obrigatório',
                    pattern: {
                      value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                      message: 'Insira um número de celular válido',
                    },
                  })}
                  name='celular'
                  id='celular'
                  type='tel'
                  placeholder='Digite seu telefone'
                  maxLength={15}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('celular', Masks.MaskPHONE(event.currentTarget.value))}
                />
                {errors.celular && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.celular.message}</p>}
              </div>
              <div className={`col-12 col-md-6 pb-3 d-flex flex-column pb-1 ${errors.datanascimento && 'error'}`}>
                <label htmlFor='datanascimento' className='fs-14 lh-18 fw-600'>Data de Nascimento</label>
                <input
                  ref={register({
                    required: 'Digite uma data válida',
                    validate: {
                      isDate: (value: string) => Validations.dateValid(value) || 'Insira uma data de nascimento válida',
                    },
                  })}
                  name='datanascimento'
                  id='datanascimento'
                  type='tel'
                  placeholder='dd/mm/aaaa'
                  maxLength={10}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('datanascimento', Masks.MaskDATE(event.currentTarget.value))}
                />
                {errors.datanascimento && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.datanascimento.message}</p>}
              </div>
              <div className={`col-12 col-md-6 pb-3 d-flex flex-column datanascimento ${errors.cpf && 'error'} `}>
                <label htmlFor='cpf' className='fs-14 lh-18 fw-600'>CPF</label>
                <input
                  ref={register({
                    required: 'CPF obrigatório',
                    validate: {
                      isCpf: (value: string) => Validations.cpf(value) || 'Insira um CPF válido',
                    },
                  })}
                  name='cpf'
                  id='cpf'
                  type='tel'
                  placeholder='Insira um CPF válido'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                />
                {errors.cpf && <p className='fs-12 lh-15 mb-0 pt-1 text-red--base text-right'>{errors.cpf.message}</p>}
              </div>
              <div className={`col-12 col-md-6 pb-3 d-flex flex-column ${errors.cliente && 'error'}`}>
                <label htmlFor='estado' className='fs-14 lh-18 fw-600'>Já tem conta no Inter?</label>
                <div className='d-flex justify-content-start pt-1'>
                  <label className='radio-contato' htmlFor='cliente'>
                    <input
                      ref={register({
                        required: 'Informação obrigatória',
                      })}
                      type='radio'
                      id='cliente'
                      name='cliente'
                      value='sim'
                    />
                    <span className='radio-check' />
                    Já tenho
                  </label>
                  <label className='radio-contato' htmlFor='naoCliente'>
                    <input
                      ref={register({
                        required: 'Informação obrigatória',
                      })}
                      type='radio'
                      id='naoCliente'
                      name='cliente'
                      value='nao'
                    />
                    <span className='radio-check' />
                    Ainda não
                  </label>
                </div>
                {errors.cliente && <p className='fs-12 mt-1 mb-0 pt-1 text-red--base text-right'>{errors.cliente.message}</p>}
              </div>
              <div className={`col-12 pb-3 d-flex flex-column ${errors.serinterone && 'error'}`}>
                <span className='fs-14 lh-18 mb-1 fw-600 text-grascale--400'>Como quero me tornar Inter One</span>
                <span className='select mb-0'>
                  <select
                    ref={register({ required: 'Selecione uma opção' })}
                    name='serinterone'
                    id='serinterone'
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => setSelect(event.target.value)}
                  >
                    <option value=''>Selecione</option>
                    <option value='Investimento'>Investindo a partir de R$ 50 mil</option>
                    <option value='FinanciamentoImobiliario'>Fazendo um financiamento imobiliário</option>
                    <option value='PortabilidadeSalario'>Trazendo meu salário de a partir de R$ 6 mil líquido</option>
                  </select>
                </span>
                {errors.serinterone &&
                  <p className='fs-12 mt-1 mb-0 pt-1 text-red--base text-right'>{errors.serinterone.message}</p>}
              </div>
              {
                select === 'Investimento' ? (
                  <div className={`col-12 pt-2 d-flex flex-column ${errors.range && 'error'}`}>
                    <span className='fs-14 lh-18 mb-1 fw-600 text-grascale--400'>Quanto pretende investir?</span>
                    <div className='col px-0'>
                      <RangeInput
                        minValue={0} maxValue={200000} interval={10000}
                        onChange={changeRange} percentageRange={PercentageInterOneJSON}
                        register={register({
                          required: 'Marque o quanto quer investir',
                          validate: {
                            min: (value: number) => value >= 10000,
                          },
                        })}
                      />
                    </div>
                    <div className='d-flex justify-content-between align-items-center flex-column'>
                      <p className='fs-12 lh-15 fw-300 mb-2'>
                        {IntervalInterOneJSON[rangeValue]}
                      </p>
                    </div>
                    {errors.range && <p className='fs-12 mt-1 mb-3 text-erro'>Marque o quanto quer investir</p>}
                    <CardAlert>
                      <div className='mr-3 d-flex'>
                        <OrangeDsIcon icon='attention' size='MD' color='#FFC700' />
                      </div>
                      <div>
                        <p className='fs-12 lh-15 fw-600 text-grayscale--500 noerro'>Valor a ser investido</p>
                        <p className='fs-12 lh-15 text-grayscale--500 noerro'>Para ser cliente Inter One, é necessário que invista valores entre R$ 50.000,00 e R$ 250.000,00 em qualquer produto da nossa plataforma.</p>
                      </div>
                    </CardAlert>
                  </div>
                ) : null
              }
              {
                (select === 'PortabilidadeSalario') && (
                  <div className='col-12'>
                    <CardAlert>
                      <div className='mr-3 d-flex'>
                        <OrangeDsIcon icon='attention' size='MD' color='#FFC700' />
                      </div>
                      <div>
                        <p className='fs-12 lh-15 fw-600 text-grayscale--500 noerro'>Portabilidade de salário</p>
                        <p className='fs-12 lh-15 text-grayscale--500 noerro'>Fazendo a portabilidade do seu salário a partir de R$ 6 mil líquido, após a recorrência de no mínimo 3 meses, você migrará automaticamente para o Inter One.</p>
                      </div>
                    </CardAlert>

                  </div>
                )

              }
              <div className='col-12 pb-3 d-flex flex-column'>
                <AcceptTerms accept={accept} setAccept={setAccept} name='conta-digital-pf' product='One' />
              </div>
              <div className='col-12 text-center mb-4 mb-md-0'>
                <button
                  type='submit' title='Enviar contato' disabled={!accept || loading}
                  className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                >
                  {loading ? 'Enviando...' : 'Enviar'}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  )
}

export default InterOneContactForm
