import React from 'react'

import { Checkbox } from './style'
import { translate, InjectedTranslateProps } from 'react-i18next'

type AcceptTermsProps = {
  accept: string | boolean;
  setAccept: (value: boolean) => boolean | void;
  name: string;
  product?: string;
}
function AcceptTerms ({ accept, setAccept, name, product, t }: AcceptTermsProps & InjectedTranslateProps) {
  function handleCheck (event: React.ChangeEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.checked
    setAccept(inputValue)
  }

  return (
    <Checkbox>
      <input
        name={name}
        value={accept.toString()}
        id={name}
        type='checkbox'
        className='form-input-check'
        onChange={handleCheck}
      />
      <label className='form-label-check fs-12 lh-15' htmlFor={name}>
        Autorizo o Inter a tratar meus dados para entrar em contato sobre me tornar cliente {product} e estou de acordo com a
        <a
          className='fw-700' title={t('titlePrivacyPolicy')}
          href='https://inter.co/politica-de-privacidade/privacidade/'
          target='_blank' rel='noreferrer'
        > Política de Privacidade
        </a>
      </label>
    </Checkbox>
  )
}

AcceptTerms.defaultProps = {
  name: 'aceite',
}

export default translate('OpenAccountRightForm')(AcceptTerms)
