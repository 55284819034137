import styled, { keyframes, css } from 'styled-components'
import { white, gray, orange } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

type ContainerProps = {
  isModal?: () => void;
}

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`

export const Container = styled.div<ContainerProps>`
  background-color: ${white};

  h3 {
    font-family: 'Sora';
  }

  input {
    height: 48px;
    border-radius: 8px;
    font-weight: 600;
  }

  ${(props: ContainerProps) => props.isModal && (
    css`
      min-height: 90vh;
      padding: 40px 22px;
      position: fixed;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      transition: 0.5s ease-in-out;
      animation: ${animatedModal} 0.5s ease-in-out forwards;
      overflow: auto;

      @media (min-width: ${breakpoints.md}) {
        max-width: 620px;
        padding: 40px 64.5px;
      }
    `
  )}

  img {
    width: 100%;
  }

  .form--default {

    label {
      color: #6A6C72;
      margin-bottom: 4px;
    }

    span.select {
      background: ${gray['400']};
      border-radius: 8px;
      height: 48px;
      display: block;
      outline: none;
      overflow: hidden;
      padding: 0 24px;
      width: 100%;
  
      &:after {
        content: "";
        border-color: transparent transparent ${orange.extra} ${orange.extra};
        border-style: solid;
        border-width: 2px;
        width: 9px;
        height: 9px;
        pointer-events: none;
        position: absolute;
        right: 30px;
        top: 37%;
        transform: rotate(315deg);
        transition: all .2s ease-in-out;
      }
      
      select {
        background: ${gray['400']}; 
        border: 0;
        height: 48px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 15px;
        width: 100%;
        font-weight: 600;
        color: #B6B7BB;
      }
    } 
  }

  &.error {
    img {
      max-width: 185px;    
    }
  }

  &.sent {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 115px;
      padding-right: 115px;
    }
    img {
      max-width: 185px;

      &.success {
        max-width: 204px;
      }
    }
  }

  &.under-age {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 115px;
      padding-right: 115px;
    }
    img {
      max-width: 204px;

      &.success {
        max-width: 185px;
      }
    }
  }

  &.filled {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  .error {
    label,
    span,
    p:not(.noerro) {
      color: #F56A50;
    }
    
    input:not([type='range']) {
      background: #FEF0ED !important;
    }
    select, .select{
      background: #FEF0ED !important;
    }
  }

`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
`
export const CardAlert = styled.div`
  background: #FFF7D9;
  border-radius: 8px;
  width: 100%;
  display: flex;
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 16px;

 
`
