import axios from 'axios'

export const apiForm = axios.create()

apiForm.interceptors.request.use((req) => {
  const object = req.data[0] || req.data
  for (const key of Object.keys(object)) {
    if (typeof object[key] === 'string') {
      object[key] = object[key].replace(/[></$%*=+?!#^)(,`~]+/g, '')
    }
  }
  return req
})
